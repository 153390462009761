<template>
  <v-img
    max-height="100vh"
    :src="require(`@/assets/images/pages/background.jpg`)"
  >
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card
          class="auth-card"
          color="#000000b5"
        >
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ appName }}
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-2">
              Forgot Password? 🔒
            </p>
            <p class="mb-2">
              Enter your username and we'll send you a provicional password to your email
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="sendEmail"
            >
              <v-text-field
                v-model="username"
                outlined
                label="Username"
                placeholder="john"
                class="mb-4"
                :rules="[validators.required]"
              ></v-text-field>

              <v-btn
                block
                color="secondary"
                type="submit"
              >
                Send email
              </v-btn>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex justify-center align-center">
            <router-link
              :to="{name:'auth-login'}"
              class="d-flex align-center text-sm"
            >
              <v-icon
                size="24"
                class="text--primary"
              >
                {{ icons.mdiChevronLeft }}
              </v-icon>
              <span class="text--primary">Back to login</span>
            </router-link>
          </v-card-actions>
        </v-card>
      </div>

      <!-- background triangle shape  -->
      <!-- <img
        class="auth-mask-bg"
        height="190"
        :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
      /> -->

      <!-- tree -->
      <!-- <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      ></v-img> -->

      <!-- tree  -->
      <!-- <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      ></v-img> -->
    </div>
  </v-img>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { error, success } from '@core/utils/toasted'
import themeConfig from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import { resetPassword } from '@api'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const { router } = useRouter()
    const isPasswordVisible = ref(false)
    const username = ref('')
    const form = ref(null)

    const sendEmail = async () => {
      if (form.value.validate()) {
        const response = await resetPassword(username.value)
        if (response.ok) {
          success(response.message)
          router.push({ name: 'auth-login' })
        } else {
          error(response.message.text)
        }
      }
    }

    return {
      isPasswordVisible,
      username,
      form,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      sendEmail,

      // Validation
      validators: {
        required,
      },

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
